.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
  background: #fff;
}
.ant-layout-sider-children {
  background: #7158e2;
}
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark {
  color: hsla(0, 0%, 100%, 0.65);
  background: #7158e2;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}
.ant-menu-dark .ant-menu-item-selected:hover {
  color: #000;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #000;
}
.site-page-header {
  background-color: #fff;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
  background: rgba(0, 0, 0, 0.01);
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  padding: 25px;
  background: rgba(255, 255, 255, 0.04);
  height: 380px;
  overflow-y: auto;
}
.chat::-webkit-scrollbar {
  width: 5px;
}
.chat::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.4);
}
.chat::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  transition: all ease 0.3s;
}
.chat::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8);
}
.chat .chat-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.chat .chat-message.chat-me {
  margin-left: auto;
}
.chat .chat-message.chat-me .chat-message-body {
  background-color: #7158e2;
  color: #fff;
}
.chat .chat-message .chat-message-body {
  padding: 15px;
  background-color: #383a3f;
  min-width: 280px;
  max-width: 450px;
  margin-right: 10px;
  color: #fff;
  border-radius: 12px;
}
.chat .chat-message .chat-message-time {
  font-style: italic;
  padding-top: 15px;
}
